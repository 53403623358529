import MetaHelmet from '../../components/Common/MetaHelmet';
import CardExternalReference from '../../components/Common/CardExternalReference';

const NutritionalReferencePage = () => {
    return (
        <>
            <MetaHelmet
                title="VegJutsu - Nutritional Reference"
                linkCanonical="https://vegjutsu.com/nutrition-reference"
                metaDescription="VegJutsu Nutritional References"
                metaKeywords="VegJutsu, Vegetarian Mobile App, Nutritional References"
            />
            <div className="container">
                <div className="row">
                    <h1>Nutritional Reference</h1>
                </div>
                <div className="row">
                    <p>
                        VegJutsu is an application that aggregates, refines and contributes information to and from various resources.
                        Here are just a few that you may also find useful.
                    </p>
                    <p>
                        VegJutsu is in no way endorsed by the sources below.  The resources listed below are simply for some insight on some of the many resources that comprise portions of information that VegJutsu is using.
                    </p>
                </div>
                &nbsp;<br />
                <CardExternalReference header="Open Food Facts" title="A collaborative, free and open database of food products from around the world." url="https://world.openfoodfacts.org/discover" buttonText="Visit Open Food Facts">
                    <div className="row">
                        <h3>A food products database</h3>
                        <p>Open Food Facts is a database of food products with ingredients, allergens, nutrition facts and all the tidbits of information we can find on product labels.</p>
                    </div>
                    <div className="row">
                        <h3>Made by everyone</h3>
                        <p>Open Food Facts is a non-profit association of volunteers.</p>
                    </div>
                    <div className="row">
                        <h3>For everyone</h3>
                        <p>Data about food is of public interest and has to be open. The complete database is published as open data and can be reused by anyone and for any use. Check-out the cool reuses or make your own!</p>
                    </div>
                    &nbsp;<br />
                    <div className='row'>
                        <p className="text-center"><i>When you contribute product information to VegJutsu, you are also contributing data to Open Food Facts.</i></p>
                    </div>

                </CardExternalReference>

                &nbsp;<br />

                <CardExternalReference header="FDA" title="United States Food and Drug Administration" url="https://www.fda.gov/food" buttonText="Visit Food and Drug Administration">
                    <p>The Food and Drug Administration is responsible for protecting the public health by ensuring the safety, efficacy, and security of human and veterinary drugs, biological products, and medical devices; and by ensuring the safety of our nation's food supply, cosmetics, and products that emit radiation.</p>
                </CardExternalReference>

                &nbsp;<br />

                <CardExternalReference header="Nova System" title="Ultra Processed Food - UPF" url="https://regulatory.mxns.com/en/ultra-processed-foods-nova-classification" buttonText="Visit Mérieux NutriSciences">
                    <div className="row">
                        <p>The definition of ultra-processed food (in English Ultra Processed Food - UPF) was coined by Carlos Monteiro, professor of Nutrition and Public Health at the University of Sao Paulo, Brazil. The scientist noted that, oddly enough, even as sugar purchases were down, the country's rates of type 2 diabetes and obesity were increasing.</p>

                        <p>Monteiro and his collaborators then went to analyze how the population's diet has changed since the 1980s, discovering that, despite consumers buying less sugar and dietary fats, consumption was increasing strongly, due to the industrial products that they contain large quantities. The common element of these products is a high degree of transformation.</p>
                    </div>
                    <div className="row">
                        <h3>NOVA Groups</h3>
                        <p>Monteiro then developed a food classification system divided into four groups, called NOVA (a name, not an acronym), based on the level of processing:</p>
                        <div className='kraken-content-list'>
                            <ul>
                                <li><b>Group 1</b> - Unprocessed or minimally processed foods (fruit, vegetables, eggs, meat, milk, etc.)</li>
                                <li><b>Group 2</b> - Foods processed in the kitchen with the aim of extending their shelf life. In practice, these are ingredients to be used in the kitchen such as fats, aromatic herbs, etc. to be kept in jars or in the refrigerator to be able to use them later.</li>
                                <li><b>Group 3</b> - Processed foods. These are the foods obtained by combining foods of groups 1 and 2 to obtain the many food products for domestic use (bread, jams, etc.) made up of a few ingredients</li>
                                <li><b>Group 4</b> - Ultra-processed foods. They are the ones that use many ingredients including food additives that improve palatability, processed raw materials (hydrogenated fats, modified starches, etc.) and ingredients that are rarely used in home cooking such as soy protein or mechanically separated meat. These foods are mainly of industrial origin and are characterized by a good pleasantness and the fact that they can be stored for a long time.</li>
                            </ul>
                        </div>
                    </div>
                </CardExternalReference>

                &nbsp;<br />

                <CardExternalReference header="Nutri-Score" title="Front of pack nutrition labelling system" url="https://www.santepubliquefrance.fr/en/nutri-score" buttonText="Visit Santé publique France">
                    <div className="row">

                        <p>Following a request from the French Ministry of Health, Santé publique France created a front of pack nutrition labelling system: the Nutri-Score which can be affixed by producers on their products on a voluntary basis. Nutri-Score was developed to facilitate consumers’ understanding of the nutritional information and thus to help them in making informed choices.</p>
                        <div className='kraken-content-list'>
                            <ul>
                                <li>A front-of-pack label that informs about the nutritional quality of a product, in a simplified and complementary way with the mandatory nutritional statement (as stated in the INCO Regulation)</li>
                                <li>Based on a five-color nutritional scale: from dark green to dark orange</li>
                                <li>Associated with letters , from A to E, to optimize the logo accessibility and understanding by the consumer</li>
                            </ul>
                        </div>
                    </div>

                </CardExternalReference>

                &nbsp;<br />

                <CardExternalReference header="USDA" title="United States Department of Agriculture" url="https://www.fns.usda.gov/healthy-eating-index-hei" buttonText="Visit USDA">
                    <div className="row">
                        <h3>Healthy Eating Index</h3>
                        <p>The HEI uses a scoring system to evaluate a set of foods. The scores range from 0 to 100. An ideal overall HEI score of 100 reflects that the set of foods aligns with key dietary recommendations from the Dietary Guidelines for Americans.</p>
                        &nbsp;<br />
                        <h3>FoodData Central</h3>
                        <p>FoodData Central takes the analysis, compilation, and presentation of nutrient and food component data to a new level. FoodData Central:</p>
                        <div className='kraken-content-list'>
                            <ul>
                                <li>Can be used by, and has benefits for, a variety of users, including researchers, policy makers, academicians and educators, nutrition and health professionals, product developers, and others.</li>
                                <li>Includes five distinct data types that provide information on food and nutrient profiles: Foundation Foods, Food and Nutrient Database for Dietary Studies 2019-2020 (FNDDS 2019-2020), National Nutrient Database for Standard Reference Legacy Release (SR Legacy), USDA Global Branded Food Products Database (Branded Foods), and Experimental Foods. Each of these data types has a unique purpose and unique attributes.</li>
                                <li>Links these distinct data types in one location, thus strengthening the ability of researchers, policy makers, and others to address vital issues related to food, nutrition, and diet-health interactions.</li>
                                <li>Provides a broad snapshot in time of the nutrients and other components found in a wide variety of foods and food products.</li>
                            </ul>
                        </div>
                    </div>
                </CardExternalReference>

            </div>
        </>
    );
};

export default NutritionalReferencePage;