import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import 'bootstrap/dist/js/bootstrap'
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Pages
import Layout from './pages/Layout';
import HomePage from './pages/public/HomePage';
import NutritionReferencePage from './pages/public/NutritionReferencePage';
import PrivacyPolicyPage from './pages/public/PrivacyPolicyPage';
import RegisterPage from './pages/public/RegisterPage';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="nutrition-reference" element={<NutritionReferencePage />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );

}

export default App;