import { useState } from 'react'
import { Link } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import './NavBar.css'

const NavBar = () => {
    const [menu, setMenu] = useState(false);

    const handleMenuClose = () => {
        console.log({ menu });
        // var isMenuTrue = ({menu} === 'true');
        // console.log("Clicky Click:" + {menu});
        setMenu(false);
    }

    const handleMenuToggle = () => {
        console.log({ menu });
        // var isMenuTrue = ({menu} === 'true');
        // console.log("Clicky Click:" + {menu});
        setMenu(!menu);
    }

    /*
    var navContentClass = "collapse navbar-collapse"
    if ({menu} === 'true') {
        navContentClass = "expand navbar-collapse"
    }
    console.log("Class:" + navContentClass)
    */

    return (
        <nav className="navbar navbar-dark bg-dark">
            <div className="container-fluid">
                <Link key="home" to="/" onClick={handleMenuClose} className="navbar-brand" >
                    VegJutsu
                </Link>
                <button className="navbar-toggler" type="button" onClick={handleMenuToggle} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Collapse in={menu}>
                    <div id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item" >
                                <Link key="nutrition-reference" to="/nutrition-reference" onClick={handleMenuClose} className="nav-link"  >
                                    Nutrition Reference
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link key="privacy-policy" to="/privacy-policy" onClick={handleMenuClose} className="nav-link"  >
                                    Privacy Policy
                                </Link>
                            </li>
                            {/*
                        <li className="nav-item dropdown">                      
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Admin
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <Link key="products" to="/admin/products" className="dropdown-item">
                                        Products
                                    </Link>
                                </li>
                                <li>
                                    <Link key="ingredients" to="/admin/ingredients" className="dropdown-item">
                                        Ingredients
                                    </Link>
                                </li>
                            </ul>
                        </li>     
                        */}
                        </ul>
                    </div>
                </Collapse>
            </div>
        </nav >
    );
}

export default NavBar;