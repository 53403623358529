function CardExternalReference({ header, title, children, url, buttonText }) {
  return (
    <div className="card">
      <h4 className="card-header">{header}</h4>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        &nbsp;<br/>
        <div className="card-text">{children}</div>
        <p><span className="material-icons">travel_explore</span>&nbsp;&nbsp;<a href={url} className="card-link">{buttonText ? `${buttonText}` : 'Click Me'}</a></p>
      </div>
    </div>
  )
};

export default CardExternalReference;