import { Helmet } from 'react-helmet'

function MetaHelmet({ title, linkCanonical, metaDescription, metaKeywords }) {
    return (
        <>
          <Helmet>
            <title>{title ? `${title}` : 'No Title'}</title>
            <link rel="canonical" href={linkCanonical ? `${linkCanonical}` : 'https://www.vegjutsu.com'} />
            <meta name="description" content={metaDescription ? `${metaDescription}` : 'VegJutsu - The Way of the Vegetarian'} />
            <meta name="keywords" content={metaKeywords ? `${metaKeywords}` : 'vegjutsu, vegetarian mobile app'} />
          </Helmet>
        </>
      )
};

export default MetaHelmet;