import React from "react";
import pjson from "../../../package.json"

const Footer = () => {
    const version = pjson.version
    return (
        <footer>
            <div className="row">
                <div className="text-center">
                    Created by <a className="kraken-link" href="http://www.krakenmobi.com">KrakenMobi.com</a>
                </div>
                <div className="text-center">
                    <p>Version: {version}</p>
                </div>
            </div>
        </footer >
    );
}

export default Footer;