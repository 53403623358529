import { Link } from "react-router-dom";

import LogoImage from '../../images/vegjutsu-logo.png'

const Header = () => {
    return (
        <header>
            <div className="row">
                <div id="header-logo" className="col-md-4">
                    <Link to="/">
                        <img src={LogoImage} alt="VegJutsu" />
                    </Link>
                </div>
                <div className="col-md-8">
                    &nbsp;<br />&nbsp;<br />
                    <h1>VegJutsu</h1>
                    <h3>The Way of the Vegetarian</h3>
                </div>              
            </div>
        </header >
    );
}

export default Header;