import React from 'react';

const RegisterPage = () => {
    return (
        <div>
            <div className="container-fluid">
                <h1>Register</h1>
                Register here...
            </div>
        </div>
    );
};

export default RegisterPage;