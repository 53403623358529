import MetaHelmet from '../../components/Common/MetaHelmet';

import Image01 from '../../images/margot-richard-01.png'
import Image02 from '../../images/tangerine-newt-01.png'
import Image03 from '../../images/gabriel-porras-01.png'
import Image04 from '../../images/paul-green-01.png'

const HomePage = () => {
    return (
        <>
            <MetaHelmet
                title="VegJutsu - The Way of the Vegetarian"
                linkCanonical="https://vegjutsu.com/"
                metaDescription="VegJutsu - The Way of the Vegetarian"
                metaKeywords="VegJutsu, Vegetarian Mobile App"
            />

            <div className="container">
                &nbsp;<br />
                <div className="row">
                    <div className="col-md-8">
                        <h1>Enabling you to do better</h1>
                        &nbsp;<br />
                        <p>
                            There are plenty of resources telling you what you shouldn't do.  VegJutsu lets you know what you CAN do in terms of how you eat.<br />
                            &nbsp;<br />
                            You may be trying to reduce your meat intake for various reasons, such as kindness to animals, world sustainability and/or for your own personal health, which are ALL good reasons that you'll find VegJutsu to be a valuable tool in your daily life.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <figure className="figure">
                            <img src={Image01} className="img-fluid rounded" alt="Do Better" />
                            <figcaption className="figure-caption text-right kraken-photo-credit">Photo by <a href="https://unsplash.com/@mrgt_rchd?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className="kraken-link">Margot RICHARD</a> on <a href="https://unsplash.com/s/photos/earth?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className="kraken-link">Unsplash</a></figcaption>
                        </figure>
                    </div>
                </div>
                &nbsp;<br />
                <div className="row">
                    <div className="col-md-4 d-none d-sm-block">
                        <figure className="figure">
                            <img src={Image02} className="img-fluid rounded" alt="Vegetarian" />
                            <figcaption className="figure-caption text-right kraken-photo-credit">Photo by <a href="https://unsplash.com/@tangerinenewt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tangerine Newt</a> on <a href="https://unsplash.com/s/photos/vegetarian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></figcaption>
                        </figure>
                    </div>
                    <div className="col-md-8">
                        <h1>Vegetarian</h1>
                        &nbsp;<br />
                        <p>
                            Are you a Vegetarian or do you share your life with a Vegetarian?<br />
                            &nbsp;<br />
                            VegJutsu helps you to not only identify which products qualify in your genre of Vegetarianism, but it also lets you see reviews and ratings of products so you could make informed decision before making your purchases.
                        </p>
                    </div>
                    <div className="col-md-4 d-block d-sm-none">
                        <figure className="figure">
                            <img src={Image02} className="img-fluid rounded" alt="Vegetarian" />
                            <figcaption className="figure-caption text-right kraken-photo-credit">Photo by <a href="https://unsplash.com/@tangerinenewt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tangerine Newt</a> on <a href="https://unsplash.com/s/photos/vegetarian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></figcaption>
                        </figure>
                    </div>
                </div>
                &nbsp;<br />
                <div className="row">
                    <div className="col-md-8">
                        <h1>Sustainability</h1>
                        &nbsp;<br />
                        <p>
                            Perhaps sustainability is a mission in your life that you would like to be a contributor.<br />
                            &nbsp;<br />
                            By understanding what food products support sustainabilty, and which ones don't, you could make better decisions one which products you are buying.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <figure className="figure">
                            <img src={Image03} className="img-fluid rounded" alt="Sustainability" />
                            <figcaption className="figure-caption text-right kraken-photo-credit">Photo by <a href="https://unsplash.com/@gabrielizalo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Gabriel Porras</a> on <a href="https://unsplash.com/s/photos/cow?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></figcaption>
                        </figure>
                    </div>
                </div>
                &nbsp;<br />
                <div className="row">
                    <div className="col-md-4 d-none d-sm-block">
                        <figure className="figure">
                            <img src={Image04} className="img-fluid rounded" alt="Health" />
                            <figcaption className="figure-caption text-right kraken-photo-credit">Photo by <a href="https://unsplash.com/es/@pgreen1983?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Paul Green</a> on <a href="https://unsplash.com/s/photos/health?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></figcaption>
                        </figure>
                    </div>
                    <div className="col-md-8">
                        <h1>Health</h1>
                        &nbsp;<br />
                        <p>
                            If you are looking to live a healthier lifestyle, a reduction in meat consumption can help.<br />
                            &nbsp;<br />
                            A plant-based diet can help you to manage weight, reduce your risk of diabetes, and also provide heart-healthy benefits.<br />
                            &nbsp;<br />
                            You don't have to be a full-fledged vegan or vegetarian to benefit from this application.  You could be all-in or just looking for better alternative for the food you are eating.
                        </p>
                    </div>
                    <div className="col-md-4 d-block d-sm-none">
                        <figure className="figure">
                            <img src={Image04} className="img-fluid rounded" alt="Health" />
                            <figcaption className="figure-caption text-right kraken-photo-credit">Photo by <a href="https://unsplash.com/es/@pgreen1983?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Paul Green</a> on <a href="https://unsplash.com/s/photos/health?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;