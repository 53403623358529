import { Outlet } from "react-router-dom";
import NavBar from '../components/Common/NavBar';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';

const Layout = () => {
    return (
        <div className="ui container">
            <Header />
            <NavBar />
            <Outlet />
            <Footer />
        </div>
    )
};

export default Layout;