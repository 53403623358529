import MetaHelmet from '../../components/Common/MetaHelmet';

const PrivacyPolicyPage = () => {
  
    return (
      <>
        <MetaHelmet 
          title="VegJutsu - Privacy Policy" 
          linkCanonical="https://vegjutsu.com/privacy-policy" 
          metaDescription = "VegJutsu - Privacy Policy"
          metaKeywords = "VegJutsu, Privacy Policy"
          />
        <div>PrivacyPolicy</div>
      </>
    );
};

export default PrivacyPolicyPage;